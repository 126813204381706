<template>
  <div>
    <img
      ref="imgRef"
      :src="imageUrl"
      style="max-width: 70%"
      @click="handleImageClick"
    />
    <div class="markerError" v-if="showImageError">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import * as markerjs2 from "markerjs2";

export default {
  name: "MarkerArea",
  props: {
    imageUrl: String,
  },
  data() {
    return {
      showImageError: false, // Control the visibility of the error message
      errorMessage: "",
    };
  },
  methods: {
    handleImageClick() {
      this.errorMessage = "";
      this.showImageError = false;
      if (this.imageUrl.includes("assets/images/uploads")) {
        this.showImageError = true; // Show the error message
        this.errorMessage = "Please choose a new image for editing.";
        setTimeout(() => {
          (this.errorMessage = ""), (this.showImageError = false);
        }, 3000);
        return; // Disable click event
      }

      this.showImageError = false; // Reset the error message visibility
      // Continue with the regular click event logic
      // For example, open the marker area
      this.showMarkerArea();
    },

    showMarkerArea() {
      if (!this.imageUrl) {
        this.showImageError = true;
        this.errorMessage = "Please select an image first.";
        setTimeout(() => {
          (this.errorMessage = ""), (this.showImageError = false);
        }, 3000);
        return;
      }

      const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
      
     
      markerArea.settings.displayMode = "popup";
      markerArea.addEventListener("render", (event) => {
        this.$emit("annotatedImage", event.dataUrl);
      });

      markerArea.show();

      const elements = document.querySelectorAll(
        '[title="Powered by marker.js"]'
      );

      elements.forEach((element) => {
        const parentElement = element.parentNode;
        parentElement.style.display = "none";
      });
      const collection = document.getElementsByClassName("__markerjs2_");
      //console.log(collection);
      for (let i = 0; i < collection.length; i++) {
        collection[i].style.zIndex = "222222";
      }
    },
  },
};
</script>

<style scoped>
.markerError {
  color: red;
}
</style>
